import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 'claro'};

export const environment = {
  ...env,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyBLrXx7cnLM4wqQa9n-b_F6qHNYTyIPDsY',
    authDomain: 'sears-shadow.firebaseapp.com',
    databaseURL: 'https://sears-shadow-default-rtdb.firebaseio.com',
    projectId: 'sears-shadow',
    storageBucket: 'sears-shadow.appspot.com',
    messagingSenderId: '225677552992',
    appId: '1:225677552992:web:f3cda22b5aaffc855bc205',
    measurementId: 'G-VBNXMRM9FH',
  },
  client,
};
